<!-- Input element -->
<div class="w-100">
  <h2 *ngIf="title" class="mb-12">{{title}}</h2>
  <label *ngIf="label">{{label}}</label>
  <a *ngIf="rightLabel && jobLob?.checkLob('RT', 'RE') " class="rightLabel-link" href="https://docs.google.com/document/d/1Ga5-fpTHIAHuZYQfuC-H2g8quJIHUnUFY67Qe6H8nQw/edit?usp=sharing" target="_blank">{{rightLabel}}</a>
  <a *ngIf="rightLabel && jobLob?.checkLob('CR')" class="rightLabel-link" href="https://docs.google.com/spreadsheets/d/1fOW4yfHxXUVz7Si97WZSKH3mwS5o2f9xl0FNBTPwrYk/edit?usp=sharing" target="_blank">{{rightLabel}}</a>
  <div class="input-group search">
    <input [attr.id]="field"
      [disabled]="(disableOnSelection && selectionMade) || isPublished || disable"
      [(ngModel)]="control ? value : inputModel"
      (ngModelChange)="check($event, false); prev();"
      [typeaheadAsync]="true"
      [typeahead]="results"
      [typeaheadHideResultsOnBlur] = "true"
      [typeaheadWaitMs]="500"
      [typeaheadOptionsLimit]="typeaheadOptionsLimit ? typeaheadOptionsLimit : 20"
      [typeaheadMinLength]="showResultsOnFocus ? 0 : null"
      (typeaheadOnSelect)="next($event, false)"
      [typeaheadScrollable]="true"
      [typeaheadOptionsInScrollableView]="6"
      [typeaheadOptionField]="typeAheadField"
      (typeaheadNoResults)="typeaheadNoResults($event)"
      [typeaheadItemTemplate]="inputTemplate"
      (keydown.arrowUp)="preventDefault($event)"
      (keydown.arrowDown)="preventDefault($event)"
      (blur)="blurred()"
      (typeaheadOnBlur)="typeaheadOnBlur($event)"
      type="search"
      class="form-control input"
      [placeholder]="placeholder || ('job-order-input.search' | translate)"
      autocomplete="na"
      [maxlength]="maxLength"/>

    <button *ngIf="useAsIs" id="useAsIsBtn" (click)="next({value: getVal()}, false)">{{'job-order-input.use_as_is' | translate}}</button>
    <button *ngIf="disableOnSelection && selectionMade && !locked" id="clearInputBtn"
      (click)="enableInput($event)"></button>

    <i *ngIf="locked" class="icon icon-WL_icon_lock color-primary"></i>
    <i *ngIf="!getVal() && !locked" class="icon icon-WL_icon_search color-primary"></i>
  </div>
  <div class="alert alert-danger mt-3" *ngIf="errorCharacterMessage">{{errorCharacterMessage}}</div>
  <div class="alert alert-danger mt-3" *ngIf="errorWordMessage">{{errorWordMessage}}</div>
  <div class="alert alert-danger mt-3" *ngIf="field === 'internalTitle' && maxTitleLength && getVal() && getVal().length > maxTitleLength">
    Client's job title cannot be more than {{maxTitleLength}} characters
  </div>
  <div class="invalid-feedback" *ngIf="noResult">{{'job-order-input.no_results_match' | translate}}</div>
  <div class="invalid-feedback" *ngIf="maxLength && getVal() && getVal().length >= maxLength">Max character limit of {{maxLength}} reached</div>
</div>

<!-- loading -->
<div *ngIf="getVal() && getVal().length && searching === field" class="w-100 mx-auto text-center m-5">
  <div class="spinner-border text-primary mx-auto text-center" role="status">
    <span class="sr-only text-center">{{'job-order-input.loading' | translate}}</span>
  </div>
</div>

<!-- Last used title and items -->
<div class="w-100 mt-2em" *ngIf="lastTitle && last?.length && completed && !completed.includes(field)">
  <h3 class="mb-12">{{lastTitle}}</h3>
  <ul class="list-group list-group-flush">
    <li *ngFor="let item of last; let i = index" (click)="next(item, true)"
      class="list-group-item list-group-item-hover" [ngClass]="{'borderTop': i === 0}">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-2">
            <div class="v-align-a">
              <div class="v-align-b">
                <img src="assets/icons/briefcase.svg" />
              </div> <!-- / .v-align-b -->
            </div> <!-- / .v-align-a -->
          </div> <!-- / .col-xs-2 -->
          <div class="col-xs-10">
            <!-- When the last item list is jobs.. -->
            <p *ngIf="item.allbirds_metadata">{{ metadata ? item.allbirds_metadata[field] : item[field]  | snippet}}</p>
            <!-- When the last item list is contacts.. -->
            <p *ngIf="item && item.item && item.item.CUST_ID">{{ item.item.FullName }}</p>
          </div> <!-- / .col-xs-10 -->
        </div> <!-- / .row -->
      </div> <!-- / .container -->
    </li>
  </ul>
</div>

<!-- drop down template -->
<ng-template #default let-model="item" let-t="object" let-query="query" let-index="index">
  <div class="col-lg-12 sb-ir-popover-table-div sb-ir-popover-table-layout">
    <div id="suggest-box" class="row text-left">
      <div class="col p-2 suggestFields" container="body">
        <span [innerHTML]="model[typeAheadField] | highlightText : query"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #workLocation let-model="item" let-t="object" let-query="query" let-index="index">
    <div class="row w-100 no-gutters suggest-header" *ngIf="index === 0">
        <div class="col-8 p-1 pl-2">{{'job-order-input.location' | translate}}</div>
        <div class="col p-1 pl-2">{{'job-order-input.address_seq' | translate}}</div>
        <div class="col p-1 pl-2">{{'job-order-input.description' | translate}}</div>
      </div>
      <div class="col-lg-12 sb-ir-popover-table-div sb-ir-popover-table-layout customDimensions">
        <div id="suggest-box" class="row text-left">
          <div class="col-8 p-2 suggestFields" [tooltip]="model[typeAheadField]" placement="right">
            <span [innerHTML]="model[typeAheadField] | highlightText : query"></span>
          </div>
          <div class="col p-2 suggestFields" container="body">
            <span [innerHTML]="model['address_seq_num'] | highlightText : query"></span>
          </div>
          <div class="col p-2 suggestFields" [tooltip]="model['description']" placement="left">
            <span [innerHTML]="model['description'] | highlightText : query"></span>
          </div>
        </div>
      </div>
  </ng-template>

<ng-template #hiringManager let-model="item" let-t="object" let-query="query" let-index="index">
  <div class="row w-100 no-gutters suggest-header" *ngIf="index === 0">
    <div class="col p-1 pl-2">{{'job-order-input.name' | translate}}</div>
    <div class="col p-1 pl-2">{{'job-order-input.contact_id' | translate}}</div>
    <div class="col-4 p-1 pl-2">{{'job-order-input.customer_name' | translate}}</div>
    <div class="col p-1 pl-2">{{'job-order-input.customer_id' | translate}}</div>
    <div class="col-2 p-1 pl-2">{{'job-order-input.title' | translate}}</div>
  </div>
  <div class="col-lg-12 sb-ir-popover-table-div sb-ir-popover-table-layout customDimensions">
    <div id="suggest-box" class="row text-left">
      <div class="col p-2 suggestFields" [tooltip]="model[typeAheadField]" placement="right">
        <span [innerHTML]="model[typeAheadField] | highlightText : query"></span>
      </div>
      <div class="col p-2 suggestFields" [tooltip]="model['ContactID']" placement="right">
        <span [innerHTML]="model['ContactID'] | highlightText : query"></span>
      </div>
      <div class="col-4 p-2 suggestFields" [tooltip]="model['CustName']" placement="right">
        <span [innerHTML]="model['CustName'] | highlightText : query"></span>
      </div>
      <div class="col p-2 suggestFields" [tooltip]="model['CUST_ID']" placement="right">
        <span [innerHTML]="model['CUST_ID'] | highlightText : query"></span>
      </div>
      <div class="col-2 p-2 suggestFields" [tooltip]="model['JobTitle']" placement="left">
        <span [innerHTML]="model['JobTitle'] | highlightText : query"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addClient let-model="item" let-t="object" let-query="query" let-index="index">
  <div class="row w-100 no-gutters suggest-header" *ngIf="index === 0">
    <div class="col p-1 pl-2">{{'job-order-input.customer_name' | translate}}</div>
    <div class="col-3 p-1 pl-2">{{'job-order-input.customer_id' | translate}}</div>
  </div>
  <div class="col-lg-12 sb-ir-popover-table-div sb-ir-popover-table-layout customDimensionsCustomer">
    <div id="suggest-box" class="row text-left">
      <div class="col p-2 suggestFields" [tooltip]="model[typeAheadField]" placement="right">
        <span [innerHTML]="model[typeAheadField] | highlightText : query"></span>
      </div>
      <div class="col-3 p-2 suggestFields" [tooltip]="model['CUST_ID']" placement="right">
        <span [innerHTML]="model['CUST_ID'] | highlightText : query"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #customer let-model="item" let-t="object" let-query="query" let-index="index">
  <div class="row w-100 no-gutters suggest-header" *ngIf="index === 0">
    <div class="col p-1 pl-2">{{'job-order-input.customer_name' | translate}}</div>
    <div class="col-3 p-1 pl-2">{{'job-order-input.customer_id' | translate}}</div>
    <div class="col p-1 pl-2">{{'job-order-input.status' | translate}}</div>
  </div>
  <div class="col-lg-12 sb-ir-popover-table-div sb-ir-popover-table-layout customDimensionsCustomer">
    <div id="suggest-box" class="row text-left">
      <div class="col p-2 suggestFields" [tooltip]="model[typeAheadField]" placement="right">
        <span [innerHTML]="model[typeAheadField] | highlightText: query"></span>
      </div>
      <div class="col-3 p-2 suggestFields" [tooltip]="model['CUST_ID']" placement="right">
        <span [innerHTML]="model['CUST_ID'] | highlightText : query"></span>
      </div>
      <div class="col p-2 suggestFields" [tooltip]="model['CUST_STATUS']" placement="right">
        <span [innerHTML]="model['CUST_STATUS'] | highlightText : query"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #skills let-model="item" let-t="object" let-query="query" let-index="index">
  <div class="col-lg-12 sb-ir-popover-table-div sb-ir-popover-table-layout">
    <div id="suggest-box" class="row text-left">
      <div class="col p-2 suggestFields"
        [tooltip]="model[typeAheadField] + (model.bullhorn_skill ? ' (bullhorn skill)':'')" placement="right">
<!--        <span [innerHTML]="model[typeAheadField]"></span>-->
<!--        <span class="pl-2" *ngIf="model.specialty" >{{model.specialty}}</span>-->
        <span [innerHTML]="model[typeAheadField] | highlightText : query"> </span>
        <span class="pl-2" *ngIf="model.specialty" [innerHTML]=" '('+ (model.specialty | highlightText : query) + ')'"></span>
        <img *ngIf="model.bullhorn_skill" class="pl-2 bullhorn-head" src="assets/images/logos/bullhorn-head.png" />
      </div>
    </div>
  </div>
</ng-template>

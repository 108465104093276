import { DistributionList } from './../../models/internal/distribution-list.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { JobOrderService } from '../job-order/job-order.service';
import { environment } from 'src/environments/environment';
import { Job } from '../../models/external/job.model';
import { User } from '../../models/external/user.model';
import { ResponseBody } from '../../models/api/application-response.interface';
import { Client, JobSkill } from '../../models/external/misc.model';
import { Profile, RawProfile } from '../../models/external/profile.model';
import SuggestsResponse = ResponseBody.SuggestsResponse;
import { RecruiterHierarchy } from '../../models/internal/recruiter-hierarchy.model';

const SERVER_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SuggestService {
  user: User;

  constructor(private http: HttpClient,
    private authService: AuthService,
    private jobOrderSerVice: JobOrderService) {
    this.user = this.authService.user;
  }

  client(searchTerm: string, options?: { contact_id?: string, customer_id?: string, aggregate?: boolean, clientIds?: string[] }) {
    let params = new HttpParams();
    params = params.append('searchTerm', searchTerm);
    params = params.append('lob', this.user.Source);
    if (options && options.contact_id) {
      params = params.append('contact_id', `${options.contact_id}`);
    }
    if (options && options.customer_id) {
      params = params.append('customer_id', `${options.customer_id}`);
    }
    if (options && options.aggregate)  {
      params = params.append('aggregate', `${options.aggregate}`);
      params = params.append('clientIds', `${options.clientIds}`);
    }
    return this.http.get<SuggestsResponse<Client>>(`${SERVER_URL}/suggest/client/`, { params: params });
  }

  contact(searchTerm: string, options?: any) {
    const lob = this.user.Source;
    let params = new HttpParams();
    params = params.append('searchTerm', searchTerm);
    params = params.append('lob', lob);
    if (options && options.customer_id) {
      params = params.append('customer_id', options.customer_id);
    }
    if (options && options.vmsContacts) {
      params = params.append('vmsContacts', options.vmsContacts);
    }
    return this.http.get<SuggestsResponse<Client>>(`${SERVER_URL}/suggest/contact/`, { params: params });
  }

  jobTitle(searchTerm: string) {
    return this.http.get<SuggestsResponse<Job>>(`${SERVER_URL}/suggest/job/` + searchTerm);
  }

  internalJob(searchTerm: string,employmentType:string = null) {
    return this.http.post<SuggestsResponse<Job>>(`${SERVER_URL}/suggest/internal/` + searchTerm,{employmentType});
  }

  publishedJob(searchTerm: string) {
    return this.http.get<SuggestsResponse<Job>>(`${SERVER_URL}/suggest/published/` + searchTerm);
  }

  emailDistributionList(searchTerm: string) {
    const lob = this.user.Source;
    let params = new HttpParams();
    params = params.append('searchTerm', searchTerm);
    params = params.append('lob', lob);
    return this.http.get<SuggestsResponse<DistributionList>>(`${SERVER_URL}/suggest/distributionLists/`, { params });
  }

  addEmailDistributionList(searchTerm: string) {
    const lob = this.user.Source;
    return this.http.post(`${SERVER_URL}/suggest/addDistributionLists`, { searchTerm, lob });
  }

  skill(searchTerm: string) {
    return this.http.get<SuggestsResponse<JobSkill>>(`${SERVER_URL}/suggest/skill/` + searchTerm);
  }

  skillBasedOnJobTitle(searchTerm: string, exclude: string[]) {
    return this.http.post<SuggestsResponse<JobSkill>>(`${SERVER_URL}/suggest/recommendedSkill/`, { searchTerm, exclude });
  }

  addNewSkill(name: string, job_title: string) {
    return this.http.post<JobSkill>(`${SERVER_URL}/suggest/addNewSkill/`, { name, job_title });
  }

  getBullhornSkills(parsedSkills: any[], lob: string) {
    return this.http.post(`${SERVER_URL}/suggest/bullhornSkills`, {skills: parsedSkills, lob});
  }

  workLocation(searchTerm: string): Observable<any> {
    const body = {
      address: searchTerm,
      customerId: this.jobOrderSerVice.jobOrderForm.controls.customer_id.value
    };
    return this.http.post(`${SERVER_URL}/suggest/workLocation`, body);
  }

  billToLocation(searchTerm: string): Observable<any> {
    const body = {
      address: searchTerm,
      customerId: this.jobOrderSerVice.jobOrderForm.controls.customer_id.value
    };
    return this.http.post(`${SERVER_URL}/suggest/billto`, body);
  }

  location(searchTerm: string): Observable<any> {
    const body = { zip: searchTerm };
    return this.http.post(`${SERVER_URL}/suggest/location`, body);
  }

  profile(searchTerm: string) {
    const body = { searchTerm };
    return this.http.post<SuggestsResponse<RawProfile>>(`${SERVER_URL}/suggest/profile`, body)
      .pipe(map(results => ({
        suggests: results.suggests.map(v => Profile.deserialize(v))
      })));
  }

  recruiter(searchTerm: string, type: RecruiterHierarchy.SuggestType, fields?: string[]): Observable<SuggestsResponse<RecruiterHierarchy.SuggestResponse>> {
    const fieldString = (fields && fields.length) ? fields.join(',') : '';
    const uri = `${SERVER_URL}/suggest/recruiter-hierarchy?type=${type}&searchTerm=${searchTerm}${fieldString ? `&fields=${fieldString}` : ''}`;
    return this.http.get<SuggestsResponse<RecruiterHierarchy.SuggestResponse>>(uri);
  }

  geolocation(searchTerm: any) {
    return this.http.post(`${SERVER_URL}/suggest/geolocation`, searchTerm);
  }

}
